import axios from '@/plugins/axios';
import utils from '@/stores/utils';
import store from '@/stores/store';

const state = {
    customers: [],
    customers_loading: false,
    customers_loading_errors: null,

    deliverysites: [],
    deliverysites_loading: false,
    deliverysites_loading_errors: null,

    orders: [],
    orders_loading: false,
    orders_loading_errors: null,

    orderitem: {},
    orderitem_saving: false,
    orderitem_saving_errors: {},

    product: {},
    products_filters: {},
};

const mutations = {
    updateCustomers(state, customers) {
        state.customers = customers;
    },
    updateCustomersLoading(state, loading) {
        state.customers_loading = loading;
    },
    updateCustomersLoadingErrors(state, errors) {
        state.customers_loading_errors = errors;
    },

    updateDeliverySites(state, deliverysites) {
        state.deliverysites = deliverysites;
    },
    updateDeliverySitesLoading(state, loading) {
        state.deliverysites_loading = loading;
    },
    updateDeliverySitesLoadingErrors(state, errors) {
        state.deliverysites_loading_errors = errors;
    },

    updateOrders(state, orders) {
        state.orders = orders;
    },
    updateOrdersLoading(state, loading) {
        state.orders_loading = loading;
    },
    updateOrdersLoadingErrors(state, errors) {
        state.orders_loading_errors = errors;
    },

    updateOrderItemSaving(state, saving) {
        state.orderitem_saving = saving;
    },
    updateOrderItemSavingErrors(state, errors) {
        state.orderitem_saving_errors = errors;
    },

    updateOrderItem(state, orderitem) {
        state.orderitem = orderitem;
        state.orders_filters = {
            exclude_readonly: true,
            ready: false,
            ordering: "order_date",
            limit: 1000,
        }

        state.products_filters = {
            product: state.orderitem.product
        };
        state.orderitem_saving_errors = [];
    },

    updateStockProduct(state, product) {
        state.product = product;
    },
};
const actions = {

    async fetchDeliverySites({ commit, rootGetters, state }, params) {
        if (rootGetters['session/current_user_permissions'].indexOf("core.view_deliverysite") === -1) {
            return;
        }

        commit('updateDeliverySitesLoading', true);
        commit('updateDeliverySitesLoadingErrors', null);

        const customer_id = params.customer_id;

        try {
            const response = await axios.get(`/api/customers/${customer_id}/deliverysites/`, { params: { limit: 1000 } });
            commit('updateDeliverySites', response.data.results);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateDeliverySitesLoadingErrors', error.details);
            throw error;
        } finally {
            commit('updateDeliverySitesLoading', false);
        }
    },

    async fetchOrders({ commit, rootGetters, state }, params) {
        if (rootGetters['session/current_user_permissions'].indexOf("order.view_order") === -1) {
            return;
        }

        commit('updateOrdersLoading', true);
        commit('updateOrdersLoadingErrors', null);

        try {
            const response = await axios.get('/api/customerorders/', { params: { ready: false, limit: 1000 } });
            commit('updateOrders', response.data.results);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateOrdersLoadingErrors', error.details);
            throw error;
        } finally {
            commit('updateOrdersLoading', false);
        }
    },

    async saveOrderItem({ commit, rootGetters, state }, params) {
        if (rootGetters['session/current_user_permissions'].indexOf("order.add_orderitem") === -1 && !state.orderitem.id) {
            return;
        }
        if (rootGetters['session/current_user_permissions'].indexOf("order.change_orderitem") === -1 && state.orderitem.id) {
            return;
        }

        commit('updateOrderItemSaving', true);
        commit('updateOrderItemSavingErrors', {});

        let url = '/api/customerorderitems/';
        let method = axios.post;

        if (state.orderitem.id) {
            url = `/api/customerorderitems/${state.orderitem.id}/`;
            method = axios.put;
        }
        try {
            const response = await method(url, state.orderitem);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateOrderItemSavingErrors', error.details);
            throw error;
        } finally {
            commit('updateOrderItemSaving', false);
        }
    },
};


export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
