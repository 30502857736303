import axios from '@/plugins/axios';
import qs from 'qs';
import utils from '@/stores/utils';
import invitations from "./submodules/invitations";

const state = {
};

const mutations = {
};

const actions = {
    init({ commit, dispatch, state }, params) {
        dispatch('invitations/init');
        dispatch('invitations/fetchInvitations');
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    modules: {
        invitations: invitations(),
    },
};
