var render = function render(){
  var _vm$errors;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tablewrapper",
    "class": _vm.className
  }, [_vm.filtersConf.length ? _c('v-card-text', {
    staticClass: "controls"
  }, [_c('Filters', {
    attrs: {
      "filters-conf": _vm.filtersConf,
      "filters": _vm.filters,
      "enable-ordering": _vm.enableOrdering,
      "exclude-from-ordering": _vm.excludeFromOrdering
    },
    on: {
      "on-filters-change": function onFiltersChange($event) {
        return _vm.onFiltersChange($event);
      }
    }
  })], 1) : _vm._e(), (_vm$errors = _vm.errors) !== null && _vm$errors !== void 0 && _vm$errors.length ? _c('Placeholder', {
    attrs: {
      "title": _vm.$gettext('Error loading data'),
      "icon": "nodata",
      "description": _vm.$gettext('An error occured while loading data. Please try again or contact us for support.'),
      "actions": [{
        text: _vm.$gettext('Retry'),
        action: 'retry'
      }]
    },
    on: {
      "on-action": _vm.onErrorAction
    }
  }) : !_vm.rowsCount && !_vm.loading ? [_c('Placeholder', {
    attrs: {
      "icon": _vm.placeholderIcon,
      "title": _vm.placeholderTitle || _vm.$gettext('No result found'),
      "description": _vm.placeholderDescription || _vm.$gettext('Nothing was found, try to clear filters or include archived items in your search.'),
      "actions": _vm.placeholderActions || []
    },
    on: {
      "on-action": function onAction($event) {
        return _vm.$emit('on-placeholder-action', $event);
      }
    }
  })] : !_vm.rowsCount && _vm.loading ? _c('Loading', {
    attrs: {
      "absolute": false
    }
  }) : _vm.rowsCount ? _c('v-data-table', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.rows || [],
      "server-items-length": _vm.rowsCount,
      "loading": _vm.loading,
      "page": _vm.page,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "must-sort": true,
      "items-per-page": _vm.itemsPerPage,
      "no-results-text": _vm.placeholderTitle || _vm.$gettext('No result found'),
      "no-data-text": _vm.placeholderTitle || _vm.$gettext('No result found'),
      "footer-props": {
        'items-per-page-options': [10, 20, 30, 40, 50, 100, -1]
      },
      "item-class": _vm.itemClass
    },
    on: {
      "update:page": function updatePage($event) {
        _vm.page = $event;
      },
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "update:itemsPerPage": function updateItemsPerPage($event) {
        _vm.itemsPerPage = $event;
      },
      "update:items-per-page": function updateItemsPerPage($event) {
        _vm.itemsPerPage = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function fn() {
        return [_vm._t("beforedata"), _vm.title || _vm.showTitle ? _c('v-toolbar', {
          attrs: {
            "flat": ""
          }
        }, [_vm.title ? _c('v-toolbar-title', [_vm._t("title", function () {
          return [_vm._v(" " + _vm._s(_vm.title) + " ")];
        })], 2) : _vm._e(), _c('v-spacer'), _vm._t("actions")], 2) : _vm._e()];
      },
      proxy: true
    }, {
      key: "item",
      fn: function fn(_ref) {
        var item = _ref.item,
          index = _ref.index,
          headers = _ref.headers;
        return [_c('tr', {
          key: index,
          "class": [item[_vm.itemClass], {
            pointer: _vm.clickableRow
          }],
          on: {
            "click": function click($event) {
              return _vm.$emit('on-row-click', item);
            }
          }
        }, _vm._l(headers, function (header) {
          return _c('td', {
            key: header.field + '-' + header.type
          }, [_vm._t(header.field, function () {
            return [header.type == 'actions' ? _c('div', {
              staticClass: "actions"
            }, [header.actions ? _vm._l(header.actions, function (action) {
              return _c('Action', {
                key: action.action,
                attrs: {
                  "icon": action.icon,
                  "type": action.type,
                  "label": action.label,
                  "color": action.color,
                  "disabled": action.disabled,
                  "placeholder": action.placeholder
                },
                on: {
                  "click": function click($event) {
                    $event.stopPropagation();
                    $event.preventDefault();
                    return _vm.handleAction(action, item);
                  }
                }
              });
            }) : _vm._e()], 2) : header.type == 'getactions' ? _c('div', {
              staticClass: "actions"
            }, _vm._l(header.get_actions(item), function (action) {
              return _c('Action', {
                key: action.action,
                attrs: {
                  "icon": action.icon,
                  "type": action.type,
                  "label": action.label,
                  "color": action.color,
                  "disabled": action.disabled
                },
                on: {
                  "click": function click($event) {
                    $event.stopPropagation();
                    $event.preventDefault();
                    return _vm.handleAction(action, item);
                  }
                }
              });
            }), 1) : header.type == 'month' ? [_vm._v(_vm._s(_vm._f("moment")(_vm.resolve(item, header), "MMM YYYY")))] : header.component ? [_c(header.component, {
              tag: "component",
              attrs: {
                "item": item
              }
            })] : header.type == 'boolean' ? [_c('span', {
              key: _vm.resolve(item, header)
            }, [_vm.resolve(item, header) ? _c('translate', [_vm._v("Yes")]) : _c('translate', [_vm._v("No")])], 1)] : header.type == 'product' ? [_vm.routeExists('product') ? _c('router-link', {
              attrs: {
                "to": {
                  name: 'product',
                  params: {
                    product_id: _vm.resolve(item, header, 'id')
                  }
                },
                "href": "/products/".concat(_vm.resolve(item, header, 'id'), "/")
              }
            }, [_vm._v(_vm._s(_vm.resolve(item, header, "fullname")))]) : [_vm._v(_vm._s(_vm.resolve(item, header, "fullname")))]] : header.type == 'customer' ? [_vm.routeExists('customer') ? _c('router-link', {
              attrs: {
                "to": {
                  name: 'customer',
                  params: {
                    customer_id: _vm.resolve(item, header, 'id')
                  }
                }
              }
            }, [_vm._v(_vm._s(_vm.resolve(item, header, "name")))]) : [_vm._v(_vm._s(_vm.resolve(item, header, "name")))]] : header.type == 'stock' ? [_vm._v(" " + _vm._s(_vm.resolve(item, header, "name")) + " ")] : header.type == 'provider' ? [_vm.routeExists('provider') ? _c('router-link', {
              attrs: {
                "to": {
                  name: 'provider',
                  params: {
                    provider_id: _vm.resolve(item, header, 'id')
                  }
                },
                "href": "/providers/".concat(_vm.resolve(item, header, 'id'), "/")
              }
            }, [_vm._v(_vm._s(_vm.resolve(item, header, "name")))]) : [_vm._v(_vm._s(_vm.resolve(item, header, "name")))]] : header.type == 'receipt' ? [_vm.routeExists('receipt') ? _c('router-link', {
              attrs: {
                "to": {
                  name: 'receipt',
                  params: {
                    receipt_id: _vm.resolve(item, header, 'receipt_id')
                  }
                },
                "href": "/receipts/".concat(_vm.resolve(item, header, 'receipt_id'), "/")
              }
            }, [_vm._v(_vm._s(_vm.resolve(item, header, "receipt_id")))]) : [_vm._v(_vm._s(_vm.resolve(item, header, "receipt_id")))]] : header.type == 'email' ? [_c('a', {
              attrs: {
                "href": "mailto:".concat(_vm.resolve(item, header))
              },
              on: {
                "click": function click($event) {
                  $event.preventDefault();
                }
              }
            }, [_vm._v(_vm._s(_vm.resolve(item, header)))])] : header.subtype == 'euros' ? [_vm.resolve(item, header) !== null ? [_vm._v(" " + _vm._s(_vm._f("price")(_vm.resolve(item, header))))] : _vm._e()] : header.type == 'months' && _vm.resolve(item, header) ? [_c('span', {
              directives: [{
                name: "translate",
                rawName: "v-translate",
                value: {
                  months: _vm.resolve(item, header)
                },
                expression: "{\n                                    months: resolve(item, header),\n                                }"
              }],
              attrs: {
                "translate-n": _vm.resolve(item, header),
                "translate-plural": "%{months} months"
              }
            }, [_vm._v(" %{ months } month ")])] : header.type == 'percentage' ? [_vm._v(_vm._s(_vm.resolve(item, header) * 100) + " % ")] : header.type == 'link' ? [_c('router-link', {
              attrs: {
                "to": header.link_to(item),
                "href": header.link_href(item)
              }
            }, [_vm._v(_vm._s(_vm.resolve(item, header)))])] : _vm.resolve(item, header) !== null ? [_vm._v(_vm._s(_vm.resolve(item, header)))] : header.placeHolder ? [_c('em', {
              staticClass: "tableplaceholder"
            }, [_vm._v(_vm._s(header.placeHolder))])] : _vm._e()];
          }, {
            "row": item,
            "header": header,
            "value": _vm.resolve(item, header.field)
          })], 2);
        }), 0)];
      }
    }], null, true)
  }) : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }