var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    key: _vm.filterName,
    staticClass: "filter text-body-2",
    "class": {
      search: _vm.filter.type == 'search'
    }
  }, [_vm.filter.type == 'search' || _vm.filter.type == 'string' || !_vm.filter.type ? [_c('v-text-field', {
    "class": {
      active: !!_vm.value
    },
    attrs: {
      "autofocus": _vm.filter.focus,
      "background-color": _vm.value ? 'primary-lighter3' : 'secondary',
      "label": _vm.filter.label,
      "placeholder": _vm.placeHolder,
      "prepend-inner-icon": _vm.iconName('search'),
      "value": _vm.value,
      "clearable": "",
      "dense": "",
      "hide-details": "",
      "outlined": "",
      "single-line": ""
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('change', $event || null);
      }
    }
  })] : _vm.filter.type == 'input' ? [_c('v-combobox', {
    staticClass: "autocomplete",
    "class": {
      active: !!_vm.value
    },
    attrs: {
      "autofocus": _vm.filter.focus,
      "background-color": !!_vm.value ? 'primary-lighter3' : 'secondary',
      "clearable": !_vm.filter.defaultLabel,
      "items": _vm._f("flattenChoices")(_vm.choices),
      "label": _vm.filter.label,
      "loading": _vm.loading,
      "menu-props": {
        closeOnClick: true
      },
      "prepend-inner-icon": _vm.filterIcon(_vm.filter.icon),
      "required": _vm.filter.required,
      "value": _vm.value,
      "dense": "",
      "flat": "",
      "hide-details": "",
      "outlined": ""
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('change', $event || null);
      }
    }
  })] : _vm.filter.type == 'boolean' ? [_c('v-checkbox', {
    key: _vm.value,
    staticClass: "text-body-2 mt-0 checkbox",
    "class": {
      active: !!_vm.value
    },
    attrs: {
      "background-color": _vm.value ? 'primary-lighter3' : 'secondary',
      "label": _vm.filter.label,
      "name": _vm.filterName,
      "input-value": _vm.value || false,
      "dense": "",
      "hide-details": "",
      "outlined": "",
      "single-line": ""
    },
    on: {
      "change": _vm.booleanInputChanged
    }
  })] : _vm.filter.type == 'select' ? [_c('v-autocomplete', {
    staticClass: "autocomplete",
    "class": {
      active: !!_vm.value
    },
    attrs: {
      "autofocus": _vm.filter.focus,
      "background-color": _vm.value ? 'primary-lighter3' : 'secondary',
      "clearable": !_vm.filter.defaultLabel,
      "items": _vm.choices,
      "label": _vm.filter.label,
      "loading": _vm.loading,
      "menu-props": {
        closeOnClick: true
      },
      "prepend-inner-icon": _vm.filterIcon(_vm.filter.icon),
      "required": _vm.filter.required,
      "value": _vm.value,
      "dense": "",
      "flat": "",
      "hide-details": "",
      "outlined": ""
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('change', $event || null);
      }
    }
  })] : _vm.filter.type == 'searchselect' ? [_c('v-autocomplete', {
    staticClass: "autocomplete",
    "class": {
      active: !!_vm.value
    },
    attrs: {
      "background-color": _vm.value ? 'primary-lighter3' : 'secondary',
      "clearable": !_vm.filter.defaultLabel,
      "items": _vm.choices,
      "label": _vm.filter.label,
      "autofocus": _vm.filter.focus,
      "loading": _vm.loading,
      "menu-props": {
        closeOnClick: true
      },
      "prepend-inner-icon": _vm.filterIcon(_vm.filter.icon),
      "required": _vm.filter.required,
      "search-input": _vm.search,
      "value": _vm.value,
      "dense": "",
      "flat": "",
      "hide-details": "",
      "outlined": ""
    },
    on: {
      "update:search-input": _vm.updateSearch,
      "input": function input($event) {
        return _vm.$emit('change', $event || null);
      }
    }
  })] : _vm.filter.type == 'datetime' ? [_c('DateTime', {
    staticClass: "autocomplete",
    "class": {
      active: !!_vm.value
    },
    attrs: {
      "clearable": true,
      "dense": true,
      "fixed": false,
      "flat": true,
      "label": _vm.filter.label,
      "autofocus": _vm.filter.focus,
      "loading": _vm.loading,
      "min-date": null,
      "outlined": true,
      "prepend-inner-icon": _vm.filterIcon(_vm.filter.icon),
      "required": _vm.filter.required,
      "value": _vm.value,
      "background-color": _vm.value ? 'primary-lighter3' : 'secondary'
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('change', $event || null);
      }
    }
  })] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }