import Vue from 'vue';
import axios from '@/plugins/axios';
import storeUtils from '@/stores/utils';
import sendinvoice from "./submodules/sendinvoice";
import utils from '@/utils';
import store from '@/stores/store';

const state = {
    billed: null,
    billingaccount: null,
    billingaccount_loading: false,
    customer_email_available: false,
    invoice_id: null,
    invoice: null,
    invoice_file: null,
    send_email: false,
    settings: {},
    validating_invoice_errors: {},
    validating_invoice: false,
};

const getters = {
    ready: (state) => {
        return state.invoice && state.billed && state.billingaccount && store.getters['validateinvoice/sendinvoice/ready'];
    },
};

const mutations = {

    updateInvoice(state, invoice) {
        state.billed = invoice?.billed_data;
        state.invoice = invoice;
        state.invoice_id = null;
        state.send_email = false;
        state.validating_invoice = false;
        state.validating_invoice_errors = {};
    },

    updateBilled(state, billed) {
        state.billed = billed;
        state.customer_email_available = !!billed?.billing_address?.email;
    },

    updateValidatingInvoice(state) {
        state.validating_invoice = true;
        state.validating_invoice_errors = {};
    },

    updateValidatingInvoiceErrors(state, validating_invoice_errors) {
        state.validating_invoice_errors = validating_invoice_errors;
        state.validating_invoice = false;
    },

    updateSendEmail(state, send_email) {
        state.send_email = send_email;
    },

    updateInvoiceID(state, invoice_id) {
        state.invoice_id = invoice_id;
    },


    updateBillingAccount(state, billingaccount) {
        state.billingaccount = billingaccount;
    },
    updateBillingAccountLoading(state, loading) {
        state.billingaccount_loading = loading;
    },

    updateInvoiceFile(state, invoice_file) {
        state.invoice_file = invoice_file;
    },

};


const actions = {

    validateInvoice({ commit, dispatch, state }, params) {

        let data = new FormData();
        let options = {};

        if (!state.billingaccount.handle_invoices) {
            data.append('invoice_file', state.invoice_file);
        }
        options.headers = { 'Content-Type': 'multipart/form-data' };
        data.append('invoice_id', state.invoice_id);
        if (state.send_email) {
            data.append('send_email', state.send_email);
            data.append('receive_copy', store.state.validateinvoice.sendinvoice['receive_copy']);
            data.append('recipients', store.state.validateinvoice.sendinvoice['recipients']);
            data.append('subject', store.state.validateinvoice.sendinvoice['subject']);
            data.append('message', store.state.validateinvoice.sendinvoice['message']);
            data.append('save_message', store.state.validateinvoice.sendinvoice['save_message']);
        }
        return new Promise((resolve, reject,) => {
            commit('updateValidatingInvoice');
            axios.post(`/api/invoices/${state.invoice.id}/validate/`, data, options)
                .then((response) => {
                    resolve(response.data);
                    dispatch('session/fetchStats', null, { root: true });
                    dispatch('billingstats/fetchAllStats', null, { root: true });
                })
                .catch((xhr_error) => {
                    const error = storeUtils.handleError(xhr_error);
                    commit('updateValidatingInvoiceErrors', error.details);
                    reject(error);
                })
        });
    },


    fetchBillingAccount({ commit, dispatch, state }, params) {
        commit('updateBillingAccountLoading', true);
        return new Promise((resolve, reject,) => {
            axios.get(`/api/billingaccounts/${state.invoice.billingaccount}/`)
                .then((response) => {
                    commit('updateBillingAccount', response.data);
                    if (response.data) {
                        commit('sendinvoice/updateMessage', response.data.invoice_notification_text || Vue.prototype.$gettext('Hello,\n\nPlease find attached invoice.\n\nBest regards.'));
                    } else {
                        commit('sendinvoice/updateMessage', null);
                    }
                    resolve(response.data);
                })
                .catch((xhr_error) => {
                    const error = storeUtils.handleError(xhr_error);
                    reject(error);
                })
                .finally(() => {
                    commit('updateBillingAccountLoading', false);
                });
        });
    },

    init({ commit, dispatch, state }, params) {
        dispatch('sendinvoice/init');

        commit('updateInvoiceFile', null);
        commit('updateInvoiceID', null);
        commit('updateSendEmail', false);
        commit('updateBillingAccount', null);
        commit('updateInvoice', params?.invoice);
        commit('updateBilled', params?.invoice?.billed_data);

        if (params.invoice) {
            dispatch('fetchBillingAccount');

            let email = params.invoice.billed_data.billing_address.email;

            dispatch('sendinvoice/init', {
                recipients: email ? [params.invoice.billed_data.billing_address.email] : [],
                subject: Vue.prototype.$gettext('New invoice') + ' - ' + utils.price(params.invoice?.total_incl_vat),
            });
        } else {
            dispatch('sendinvoice/init');
        }

    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
    modules: {
        sendinvoice: sendinvoice(),
    }

};
