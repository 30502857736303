import Vue from 'vue';
import Vuex from 'vuex';
//import VuexPersistence from 'vuex-persist'

import account from './module-account';
import addtoorder from './module-addtoorder';
import addtoproviderorder from './module-addtoproviderorder';
import addinvoiceitems from './module-addinvoiceitems';
import addtoreceipt from './module-addtoreceipt';
import addtoshipment from './module-addtoshipment';
import adjuststock from './module-adjuststock';
import assigncatalog from './module-assigncatalog';
import assignedcatalog from './module-assignedcatalog';
import billing from './module-billing';
import billingaccount from './module-billingaccount';
import billingaccounts from './module-billingaccounts';
import billingstats from './module-billingstats';
import catalog from './module-catalog';
import catalogentry from './module-catalogentry';
import catalogs from './module-catalogs';
import contractline from './module-contractline';
import createordershipment from './module-createordershipment';
import productstrategy from './module-productstrategy';
import customer from './module-customer';
import customers from './module-customers';
import editinvoice from './module-editinvoice';
import editinvoiceitem from './module-editinvoiceitem';
import editcustomer from './module-editcustomer';
import editshipment from './module-editshipment';
import editreceipt from './module-editreceipt';
import editcustomerorder from './module-editcustomerorder';
import editproviderorder from './module-editproviderorder';
import editorderitemcount from './module-editorderitemcount';
import editproduct from './module-editproduct';
import deliverysite from './module-deliverysite';
import deliverysites from './module-deliverysites';
import editdeliverysite from './module-editdeliverysite';
import events from './module-events';
import help from './module-help';
import invitation from './module-invitation';
import invitations from './module-invitations';
import invoice from './module-invoice';
import invoices from './module-invoices';
import mcl from './module-mcl';
import missingreceiptitem from './module-missingreceiptitem';
import order from './module-order';
import providerorder from './module-providerorder';
import customerorders from './module-customerorders';
import operators from './module-operators';
import providerorders from './module-providerorders';
import printlabels from './module-printlabels';
import product from './module-product';
import providerproduct from './module-providerproduct';
import purchaseprices from './module-purchaseprices';
import products from './module-products';
import producttype from './module-producttype';
import producttypes from './module-producttypes';
import provider from './module-provider';
import providers from './module-providers';
import catalogpublishing from './module-catalogpublishing';
import receipt from './module-receipt';
import receipts from './module-receipts';
import searchcatalog from './module-searchcatalog';
import sendinvoice from './module-sendinvoice';
import sendorderinvoice from './module-sendorderinvoice';
import session from './module-session';
import settings from './module-settings';
import shipment from './module-shipment';
import shipments from './module-shipments';
import stock from './module-stock';
import stocklocation from './module-stocklocation';
import stocks from './module-stocks';
import stockstats from './module-stockstats';
import synchronizeallocations from './module-synchronize-allocations';
import synchronizeproducts from './module-synchronize-products';
import synchronizecatalog from './module-synchronize-catalog';
import synchronizestockproducts from './module-synchronize-stockproducts';
import synchronizecustomers from './module-synchronize-customers';
import user from './module-user';
import users from './module-users';
import zoneedit from './module-zone-edit';
import zones from './module-zones';
import validateinvoice from './module-validateinvoice';
import allocation from './module-allocation';
import allocations from './module-allocations';
import movements from './module-movements';
import group from './module-group';
import requestcatalog from './module-requestcatalog';

Vue.use(Vuex);

const modules = {
    account,
    addtoorder,
    addtoproviderorder,
    addtoreceipt,
    addtoshipment,
    addinvoiceitems,
    adjuststock,
    allocation,
    allocations,
    assigncatalog,
    assignedcatalog,
    billing,
    billingaccount,
    billingaccounts,
    billingstats,
    catalog,
    catalogentry,
    catalogs,
    contractline,
    createordershipment,
    productstrategy,
    customer,
    customers,
    editreceipt,
    editcustomer,
    editinvoice,
    editinvoiceitem,
    editshipment,
    editcustomerorder,
    editproviderorder,
    editorderitemcount,
    editproduct,
    deliverysite,
    deliverysites,
    editdeliverysite,
    events,
    help,
    group,
    invitation,
    invitations,
    invoice,
    invoices,
    mcl,
    missingreceiptitem,
    movements,
    order,
    operators,
    providerorder,
    customerorders,
    providerorders,
    printlabels,
    product,
    providerproduct,
    purchaseprices,
    products,
    producttype,
    producttypes,
    provider,
    providers,
    catalogpublishing,
    receipt,
    receipts,
    requestcatalog,
    searchcatalog,
    sendinvoice,
    sendorderinvoice,
    session,
    settings,
    shipment,
    shipments,
    stock,
    stocklocation,
    stocks,
    stockstats,
    synchronizeallocations,
    synchronizecatalog,
    synchronizeproducts,
    synchronizestockproducts,
    synchronizecustomers,
    user,
    users,
    validateinvoice,
    zoneedit,
    zones,
};

const state = {
};

const mutations = {
};

const actions = {
};

// const vuexLocal = new VuexPersistence({
//   storage: window.localStorage
// })


const store = new Vuex.Store({
    modules,
    state,
    mutations,
    actions,
});

export default store;
