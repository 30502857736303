import qs from 'qs';
import axios from '@/plugins/axios';
import utils from '@/stores/utils';

const createState = () => ({
    catalogentries: [],
    catalogentries_stats: {},
    catalogentries_url: "/api/catalogentries/",
    catalogentries_count: 0,
    catalogentries_filters: { offset: 0, limit: 20, ordering: 'product_name' },
    catalogentries_loaded: false,
    catalogentries_loading: false,
    catalogentries_loading_errors: null,

    catalogentry_deleting: false,
    catalogentry_deleting_errors: null,

    fetch_stats: true,
});


const mutations = {

    updateCatalogEntries(state, catalogentries) {
        state.catalogentries = catalogentries;
        if (catalogentries !== null) {
            state.catalogentries_loaded = true;
        }
        state.catalogentries_loading = false;
    },
    updateCatalogEntriesCount(state, count) {
        state.catalogentries_count = count;
    },
    updateCatalogEntriesLoading(state, loading) {
        state.catalogentries_loading = loading;
    },
    updateCatalogEntriesLoadingErrors(state, errors) {
        state.catalogentries_loading_errors = errors;
    },
    updateCatalogEntriesFilters(state, filters) {
        state.catalogentries_filters = filters;
    },
    updateCatalogEntryDeleting(state, deleting) {
        state.catalogentry_deleting = deleting;
    },
    updateCatalogEntryDeletingErrors(state, errors) {
        state.catalogentry_deleting_errors = errors;
    },
    updateCatalogEntriesUrl(state, url) {
        state.catalogentries_url = url;
    },
    updateCatalogEntriesStats(state, stats) {
        state.catalogentries_stats = stats;
    },

    updateFetchStats(state, fetch_stats) {
        state.fetch_stats = fetch_stats;
    }
};


const getters = {

};

const actions = {

    deleteCatalogEntry({ commit, dispatch, state }, params) {
        commit('updateCatalogEntryDeleting', true);
        commit('updateCatalogEntryDeletingErrors', null);

        return new Promise((resolve, reject) => {

            const url = `/api/catalogentries/${params.instance.id}/`;
            axios.delete(url)
                .then((response) => {
                    commit('updateCatalogEntryDeleting', false);
                    resolve(response.data);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateCatalogEntryDeleting', false);
                    commit('updateCatalogEntryDeletingErrors', error.details);
                    reject(error);
                });
        });
    },

    saveCatalogEntry({ commit, dispatch, state }, params) {
        commit('updateCatalogEntrySaving', true);

        return new Promise((resolve, reject) => {
            let url = '/api/catalogentries/';
            let method = axios.post;
            if (params.instance.id) {
                url = `/api/catalogentries/${params.instance.id}/`;
                method = axios.put;
            }

            if (params.params) {
                const queryparams = qs.stringify(params.params, { arrayFormat: 'repeat' });
                if (queryparams) {
                    url += `?${queryparams}`;
                }
            }
            method(url, params.instance)
                .then((response) => {
                    commit('updateCatalogEntrySaving', false);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateCatalogEntrySavingErrors', error.details);
                    reject(error);
                });
        });
    },

    fetchCatalogEntries({ commit, dispatch, state }, params) {

        commit('updateCatalogEntriesLoading', true);
        commit('updateCatalogEntriesLoadingErrors', null);
        return new Promise((resolve, reject) => {
            axios.get(state.catalogentries_url, { params: state.catalogentries_filters })
                .then((response) => {
                    commit('updateCatalogEntries', response.data.results);
                    commit('updateCatalogEntriesCount', response.data.count);
                    resolve(response.data.payload);

                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateCatalogEntriesLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateCatalogEntriesLoading', false);
                });
        });
    },


    fetchCatalogEntriesStats({ commit, dispatch, state }, params) {
        if (!state.fetch_stats) {
            return;
        }
        return new Promise((resolve, reject) => {
            axios.get(state.catalogentries_url + 'stats/')
                .then((response) => {
                    commit('updateCatalogEntriesStats', response.data);
                    resolve(response.data);
                })
                .catch((xhr_error) => {
                    reject(utils.handleError(xhr_error));
                });
        });
    },

    init({ commit, dispatch, state }, params) {
        commit('updateCatalogEntries', []);
        commit('updateFetchStats', params.fetch_stats === undefined ? true : params.fetch_stats);
        commit('updateCatalogEntriesFilters', Object.assign({}, state.catalogentries_filters, params.filters || {}));
        commit('updateCatalogEntriesUrl', params.url || "/api/catalogentries/");
        dispatch('fetchCatalogEntriesStats');
    }
};

export default () => ({
    namespaced: true,
    state: createState(),
    mutations,
    actions
});