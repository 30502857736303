<template>
    <v-card v-if="readonly" class="mb-8">
        <v-card-title class="text-h2 pa-3 pa-sm-6">
            <translate>Readonly account</translate>
        </v-card-title>

        <v-card-text class="px-3 px-sm-6">
            <div>
                {{
                    $gettext(
                        "Your account is currently in readonly mode. Please contact your sales representative at the following address"
                    )
                }}
                <a href="mailto:contact@safetee.io"> contact@safetee.io</a>.
            </div>
            <Button
                class="mt-6 mb-4"
                :text="$gettext('Contact us')"
                :color="'primary'"
                :icon="iconName('email')"
                @click="sendMail"
            />
        </v-card-text>
    </v-card>
</template>

<script>
import GlobalMixin from "@/mixins/global";
import { mapState, mapGetters } from "vuex";

export default {
    mixins: [GlobalMixin],
    computed: Object.assign(mapState("session", ["readonly", "status"]), {
        alertType() {
            return "error";
        },
    }),
    methods: {
        sendMail() {
            window.open("mailto:contact@safetee.io");
        },
    },
};
</script>
