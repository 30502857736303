<template>
    <div
        class="pa-9 d-flex justify-center placeholder"
        :style="{ 'min-height': minHeight }"
    >
        <div class="d-flex">
            <div class="flex-column text-center d-flex justify-center">
                <MasterSvg :use="icon" />
                <div class="placeholder--title" v-if="title">
                    {{ title }}
                </div>
                <div
                    class="mt-4 px-md-12 d-flex text-caption text-gs-semibold flex-column align-center"
                    v-if="description || actions"
                >
                    <span v-if="description" class="placeholder--description">
                        {{ description }}
                    </span>
                    <div
                        class="placeholder--actions d-flex flex-column mt-5"
                        v-if="actions?.length"
                    >
                        <Button
                            :text="action.text"
                            class="mb-4"
                            :disabled="action.disabled"
                            :icon="action.icon ? iconName(action.icon) : null"
                            @click="onAction(action)"
                            v-for="action in actions"
                            :ghost="!action.main"
                            :key="action.action"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import GlobalMixins from "@/mixins/global";

export default {
    mixins: [GlobalMixins],
    props: {
        icon: {
            type: String,
            default: "folder",
        },
        title: {
            type: String,
            required: false,
        },
        description: {
            type: String,
            required: false,
        },
        actions: {
            type: Array,
            required: false,
            default: function () {
                return [];
            },
        },
        minHeight: {
            type: Number,
            default: 350,
        },
    },
    methods: {
        onAction(action) {
            this.$emit(action.action);
            this.$emit("on-action", action.action);
        },
    },
};
</script>

<style lang="scss">
.placeholder--title {
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 16px;
    margin-top: 30px;
}
.placeholder--actions {
    display: block;
}
.placeholder--description {
    max-width: 900px;
    font-size: 14px;
    line-height: 25px;
}
</style>
