import store from '@/stores/store';
import axios from '@/plugins/axios';
import utils from '@/stores/utils';

const state = {
    product: null,
    product_loading: false,
    product_loading_errors: null,
    product_deleting: false,

    product_saving: false,
    product_saving_errors: {},

    producttype: null,
    producttypes: [],
    producttypes_loading: false,
    producttypes_loading_errors: null,

    purchaseprices: [],
    purchaseprices_count: 0,
    purchaseprices_loading: false,
    purchaseprices_loading_errors: null,
    purchaseprices_saving: false,
    purchaseprices_saving_errors: null,

    providers_loading: false,
    providers_loading_errors: null,
    providers: [],
    providers_count: 0,
};


function cleanupMetadata(metadata, producttype) {
    if (!metadata || !producttype) {
        return metadata;
    }
    const new_metadata = {};
    for (const key in metadata) {
        const field = producttype.fields.find((item) => item.code === key);
        if (!field) {
            continue;
        }
        const value = metadata[key];
        if (field.type === 'boolean') {
            new_metadata[key] = value === 'true' || value === true;
        } else if (field.type === 'float') {
            const float = parseFloat(value);
            new_metadata[key] = isNaN(float) ? null : float;
        } else if (field.type === 'integer' || field.type === 'number') {
            const int = parseInt(value);
            new_metadata[key] = isNaN(int) ? null : int;
        } else if (field.type === 'string') {
            new_metadata[key] = value;
        } else if (field.type === 'choices') {
            if (field.choices.includes(value)) {
                new_metadata[key] = value;
            } else {
                new_metadata[key] = null;
            }
        } else {
            console.log(`field ${key} has unknown type ${field.type}`);
            new_metadata[key] = value;
        }
    }
    return new_metadata;
}

const mutations = {

    updateProduct(state, product) {
        state.product_saving = false;
        state.product_saving_errors = {};

        if (product && state.product && product.id && state.product.id) {
            state.product = product;
            return;
        }
        state.purchaseprices = [];
        state.purchaseprices_count = 0;
        state.purchaseprices_loading_errors = null;
        state.product = product;
        state.producttype = product.producttype;
    },

    updateProductType(state, producttype) {
        state.producttype = producttype;
        state.product.producttype = producttype;
    },

    updateProductLoading(state, loading) {
        state.product_loading = loading;
    },
    updateProductLoadingErrors(state, errors) {
        state.product_loading_errors = errors;
    },

    updateProductSavingErrors(state, errors) {
        state.product_saving = false;
        state.product_saving_errors = errors;
    },
    updateProductSaving(state, saving) {
        state.product_saving = saving;
    },

    updateProductTypes(state, producttypes) {
        state.producttypes = producttypes;
    },
    updateProductTypesLoading(state, loading) {
        state.producttypes_loading = loading;
    },
    updateProductTypesLoadingErrors(state, errors) {
        state.producttypes_loading_errors = errors;
    },

    updatePurchasePrices(state, purchaseprices) {
        state.purchaseprices = purchaseprices;
    },
    updatePurchasePricesCount(state, count) {
        state.purchaseprices_count = count;
    },
    updatePurchasePricesLoading(state, loading) {
        state.purchaseprices_loading = loading;
    },
    updatePurchasePricesLoadingErrors(state, errors) {
        state.purchaseprices_loading_errors = errors;
    },
    updatePurchasePricesSavingErrors(state, errors) {
        state.purchaseprices_saving = false;
        state.purchaseprices_saving_errors = errors;
    },
    updatePurchasePricesSaving(state, saving) {
        state.purchaseprices_saving = saving;
    },

    updateProvidersLoading(state, loading) {
        state.providers_loading = loading;
    },
    updateProvidersLoadingErrors(state, errors) {
        state.providers_loading_errors = errors;
    },
    updateProviders(state, providers) {
        state.providers = providers;
    },
    updateProvidersCount(state, count) {
        state.providers_count = count;
    },

};

const actions = {


    async fetchProduct({ commit, dispatch, state }, params) {
        commit('updateProductLoading', true);

        let url = state.product.provider ?
            `/api/providerproducts/${state.product.id}/` :
            `/api/products/${state.product.id}/`;

        try {
            const response = await axios.get(url);
            commit('updateProduct', response.data);
            return response.data;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateProductLoadingErrors', error.details);
            throw error;
        } finally {
            commit('updateProductLoading', false);
        }
    },

    async saveProduct({ commit, dispatch, state }, params) {
        let url = state.product.provider ? '/api/providerproducts/' : '/api/products/';
        let method = axios.put;
        const producttype = state.producttypes.find((item) => item.id === state.product.producttype);
        state.product.metadata = cleanupMetadata(state.product.metadata, producttype);
        if (state.product?.id) {
            url += `${state.product.id}/`;
            delete (state.product.providers);
            method = axios.patch;
        } else {
            method = axios.post;
        }
        try {
            const response = await method(url, state.product);
            dispatch('session/fetchStats', null, { root: true });
            return response;
        } catch (xhr_error) {
            let error = utils.handleError(xhr_error);
            commit('updateProductSavingErrors', error.details);
            throw error;
        }
    },

    fetchProductTypes({ commit, dispatch, state }, params) {
        commit('updateProductTypesLoading', true);
        commit('updateProductTypesLoadingErrors', null);

        return new Promise((resolve, reject) => {
            axios.get('/api/producttypes/', { params: { limit: 100 } })
                .then((response) => {
                    commit('updateProductTypes', response.data.results);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateProductTypesLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateProductTypesLoading', false);
                })
        });
    },

    async init({ commit, dispatch, state }, params) {
        let product = Object.assign(
            {
                metadata: {},
                purchase_prices: [],
                packaging: store.state.session.settings.default_packaging,
            },
            JSON.parse(
                JSON.stringify(
                    params.product || { purchase_prices: [], metadata: {} }
                )
            )
        );
        commit('updateProduct', product);
        dispatch('fetchProductTypes');
    },

};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};

