import axios from '@/plugins/axios';
import qs from 'qs';
import utils from '@/stores/utils';
import store from '@/stores/store';
import catalogentries from './submodules/catalogentries';

const state = {
    mode: 'operator',
    section: null,

    catalog: {},
    catalog_loading: false,
    catalog_loading_errors: null,
    catalog_saving: false,
    catalog_saving_errors: {},
    catalog_deleting: false,
    catalog_deleting_errors: null,

    catalogentry: false,
    catalogentry_loading: false,
    catalogentry_loading_errors: null,
    catalogentry_saving: false,
    catalogentry_saving_errors: {},

};


const mutations = {
    updateSection(state, section) {
        state.section = section;
    },

    updateCatalog(state, catalog) {
        state.catalog = catalog;
        state.catalog_loading = false;
        state.catalog_loading_errors = null;

        if (catalog && state.catalog && state.catalog.id == catalog.id) {
            return;
        }
    },

    updateCatalogLoading(state) {
        state.catalog_loading = true;
        state.catalog_loading_errors = null;
    },
    updateCatalogLoadingErrors(state, errors) {
        state.catalog_loading = false;
        state.catalog_loading_errors = errors;
    },
};


const getters = {

};

const actions = {

    exportToCSV({ commit, dispatch, state }, params) {
        let filters = {
            ...{
                format: 'csv',
                limit: 10000,
                catalog: state.catalog.id,
            }, ...(params || {})
        };
        const queryparams = qs.stringify(filters, { arrayFormat: 'repeat' });
        window.open(`/api/assignedcatalogentries/?${queryparams}`);
    },

    fetchCatalog({ commit, dispatch, state }, params) {
        commit('updateCatalogLoading', true);

        return new Promise((resolve, reject) => {
            let url;
            if (params?.catalog_id) {
                url = `/api/assignedcatalogs/${params.catalog_id}/`;
            } else if (state?.catalog) {
                url = `/api/assignedcatalogs/${state.catalog.id}/`;
            } else {
                throw 'No catalog to fetch';
            }
            axios.get(url)
                .then((response) => {
                    commit('updateCatalogLoading', false);
                    commit('updateCatalog', response.data);
                    resolve(response.data);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateCatalogLoadingErrors', error.details);
                    reject(error)
                });
        });
    },

    async init({ commit, dispatch, state }, params) {
        commit('updateCatalog', params.catalog);
        if (!params.catalog.id) {
            return;
        }

        dispatch('entries/init', {
            url: `/api/assignedcatalogentries/`,
            catalog: params.catalog,
            fetch_stats: false,
            filters: {
                catalog: params.catalog.id,
                ordering: 'name',
            }
        });
        dispatch('entries/fetchCatalogEntries');
    }

};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
    modules: {
        entries: catalogentries(),
    }
};
