import { mapGetters, mapActions, mapState } from "vuex";
import { isMobile as _isMobile } from "mobile-device-detect";
import Help from "@/modals/help.vue";
import utils from "@/utils.js";

export default {
    methods: Object.assign(
        mapActions("mcl", ["showModal", "hideModal"]),
        mapActions("session", ["addAlert"]),
        {
            getStockName(stock) {
                if (!stock) {
                    return "";
                }
                return stock.owned
                    ? stock.name
                    : `${stock.name} (${stock.owner_data?.name || stock.owner_name || this.$gettext('external')})`;
            },
            getDeliverySiteName(deliverysite) {
                if (!deliverysite) {
                    return "";
                }
                if (deliverysite.stock) {
                    return this.getStockName(deliverysite.stock_data);
                }
                if (!deliverysite.customer) {
                    return `${deliverysite.name} (${deliverysite.entity_data?.name || this.$gettext('external')})`;
                }
                return deliverysite.name;
            },

            openHelp(helpContentId) {
                this.showModal({
                    component: Help,
                    params: {
                        contentUniqueId: helpContentId,
                    },
                });
            },
            userHasPermission(perm) {
                if (
                    this.readonly &&
                    (perm.indexOf(".change_") != -1 ||
                        perm.indexOf(".add_") != -1 ||
                        perm.indexOf(".delete_") != -1)
                ) {
                    return false;
                }
                return this.current_user_permissions.indexOf(perm) != -1;
            },
            currentEntityHasFeature(code) {
                return this.current_entity_features.find(
                    (f) => f.code == code && f.profile == this.current_entity_profile
                );
            },
            iconName(code) {
                if (!code) {
                    throw new Error("iconName: code is required");
                }
                return utils.icon(code);
            },
            filtersEmpty(filters) {
                filters = Object.assign(filters);
                if (filters.offset) {
                    delete filters.offset;
                }
                if (filters.limit) {
                    delete filters.limit;
                }
                return Object.keys(filters).length == 0;
            },
            swalError(error) {
                this.$swal({
                    title: this.$gettext("Error"),
                    html: this.$utils.resultToSwalContent(this, error),
                    type: "error",
                });
            },
            swalErrorForField(result, field) {
                this.$swal({
                    title: this.$gettext("Error"),
                    html: this.$utils.resultToSwalContentForField(this, result, field),
                    type: "error",
                });
            },
            routeExists(routename) {
                return !!this.$router.options.routes.find(
                    (route) => route.name == routename
                );
            },
            price(value) {
                if (value === null) {
                    return null;
                }
                return utils.price(value);
            },
            moment(date, format) {
                if (!date) {
                    return "-";
                }
                return this.$moment(date).format(format || "LL");
            },
        }
    ),
    computed: Object.assign(
        mapGetters("session", [
            "current_user",
            "current_user_mode",
            "current_user_permissions",
            "current_entity_features",
            "readonly",
            "current_entity_profile",
            "current_entity_id",
            "current_entity",
            "current_entity_stats",
        ]),
        {
            hasMainAccount() {
                return !this.current_entity.parent;
            },
            isOperator() {
                return this.current_entity_profile == "operator";
            },
            isCustomer() {
                return this.current_entity_profile == "customer";
            },
            hasParent() {
                return !!this.current_entity.parent;
            },
            isAgent() {
                return this.current_entity_profile == "agent";
            },
            isMobile() {
                return _isMobile;
            },
            isReadOnly() {
                return this.readonly;
            },
        }
    ),
};
