import axios from '@/plugins/axios';
import utils from '@/stores/utils';
import store from '@/stores/store';

const createState = () => ({
    customers: [],
    customers_url: '/api/customers/',
    customers_filters: { offset: 0, limit: 20, },
    customers_count: 0,
    customers_loaded: false,
    customers_loading: false,
    customers_loading_errors: null,
});

const mutations = {
    updateCustomers(state, customers) {
        state.customers = customers;
        state.customers_loaded = true;
        state.customers_loading = false;
        state.customers_loading_errors = null;
    },
    updateCustomersUrl(state, customers_url) {
        state.customers_url = customers_url;
    },
    updateCustomersFilters(state, customers_filters) {
        state.customers_filters = customers_filters;
    },
    updateCustomersLoading(state, customers_loading) {
        state.customers_loading = customers_loading;
    },
    updateCustomersLoadingErrors(state, customers_loading_errors) {
        state.customers_loading_errors = customers_loading_errors;
        state.customers_loading = false;
    },
    updateCustomersCount(state, customers_count) {
        state.customers_count = customers_count;
    },
};

const actions = {

    fetchCustomers({ commit, dispatch, state }, params) {
        if (store.getters['session/current_user_permissions'].indexOf("core.view_customer") == -1) {
            return;
        }

        commit('updateCustomersLoading', true);
        commit('updateCustomersLoadingErrors', null);

        return new Promise((resolve, reject) => {
            axios.get(state.customers_url, { params: state.customers_filters })
                .then((response) => {
                    commit('updateCustomers', response.data.results);
                    commit('updateCustomersCount', response.data.count);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateCustomersLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateCustomersLoading', false);
                });

        });
    },

    init({ commit, dispatch, state }, params) {
        commit('updateCustomers', []);
        commit('updateCustomersFilters', params.filters || {});
        if (params.url) {
            commit('updateCustomersUrl', params.url);
        }
    },
};


export default () => ({
    namespaced: true,
    state: createState(),
    mutations,
    actions
});