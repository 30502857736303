import qs from 'qs';
import axios from '@/plugins/axios';
import utils from '@/stores/utils';

const state = {
    active_mode: null,
    invoices: [],
    invoices_count: 0,
    invoices_filters: { offset: 0, limit: 20, ordering: '-invoice_date,billed_name', myinvoices: false },
    invoices_loading: false,
    invoices_loading_errors: null,
    invoices_cancel_source: null,

    billingaccounts: [],
    billingaccounts_loading: false,
    billingaccounts_loading_errors: null,

    invoices_stats: {},
    invoices_stats_loading: false,
    invoices_stats_loading_errors: null,
    invoices_stats_cancel_source: null,
};


const modeFilters = {
    draft: {
        status: 'draft',
    },
    waitingdelivery: {
        status: 'validated',
        sent: false,
        paid: false,
    },
    waitingpayment: {
        status: 'validated',
        sent: true,
        paid: false,
    },
    paid: {
        status: 'validated',
        paid: true,
    },
    all: {},
};

let getModeFilters = function (active_mode) {
    return modeFilters[active_mode] || {};
};

const mutations = {
    updateInvoices(state, invoices) {
        state.invoices = invoices;
    },
    updateInvoicesCount(state, invoices_count) {
        state.invoices_count = invoices_count;
    },
    updateInvoicesLoading(state, loading) {
        state.invoices_loading = loading;
    },
    updateInvoicesLoadingErrors(state, errors) {
        state.invoices_loading_errors = errors;
    },

    updateInvoicesStats(state, invoices_stats) {
        state.invoices_stats = invoices_stats;
    },
    updateInvoicesStatsLoading(state, loading) {
        state.invoices_stats_loading = loading;
    },
    updateInvoicesStatsLoadingErrors(state, errors) {
        state.invoices_stats_loading_errors = errors;
    },
    updateInvoicesFilters(state, invoices_filters) {
        state.invoices_filters = Object.assign(
            {
                limit: state.invoices_filters.limit || 20,
                ordering: state.invoices_filters.ordering,
            },
            getModeFilters(state.active_mode),
            invoices_filters);
    },
    updateInvoicesCancelSource(state, source) {
        state.invoices_cancel_source = source;
    },
    updateInvoicesStatsCancelSource(state, source) {
        state.invoices_stats_cancel_source = source;
    },

    updateMode(state, active_mode) {
        state.active_mode = active_mode;
        state.invoices = [];
        state.invoices_count = 0;

        state.invoices_filters = {
            offset: 0,
            limit: state.invoices_filters.limit || 20,
            search: state.invoices_filters.search,
            ordering: state.invoices_filters.ordering,
            billed: state.invoices_filters.billed,
            provider: state.invoices_filters.provider,
            myinvoices: false,
        };
        state.invoices_filters = Object.assign({}, state.invoices_filters, getModeFilters(state.active_mode));
    },

    updateBillingAccounts(state, billingaccounts) {
        state.billingaccounts = billingaccounts;
        state.billingaccounts_loading = false;
        state.billingaccounts_loading_errors = null;
    },
    updateBillingAccountsLoading(state, billingaccounts_loading) {
        state.billingaccounts_loading = billingaccounts_loading;
    },
    updateBillingAccountsLoadingErrors(state, billingaccounts_loading_errors) {
        state.billingaccounts = [];
        state.billingaccounts_loading = false;
        state.billingaccounts_loading_errors = billingaccounts_loading_errors;
    },
};

const actions = {
    async changeMode({ commit, dispatch, state }, mode) {
        if (mode == state.active_mode) {
            return;
        }
        commit('updateMode', mode);
        await dispatch('fetchInvoices');
        dispatch('session/fetchStats', null, { root: true });
    },

    async markInvoicedAsPaid({ commit, dispatch, state }, params) {
        try {
            const response = await axios.post(`/api/invoices/${params.invoice.id}/paid/`, { paid: true });
            dispatch('session/fetchStats', null, { root: true });
            dispatch('billingstats/fetchAllStats', null, { root: true });
            return response.data;
        } catch (xhr_error) {
            throw utils.handleError(xhr_error);
        }
    },

    exportInvoicesAsCSV({ commit, dispatch, state }, params) {
        let filters = {};
        if (params.filtered) {
            filters = Object.assign({}, state.invoices_filters);
            filters.offset = null;
        }
        filters.format = 'csv';
        filters.limit = 10000;
        const queryparams = qs.stringify(filters, { arrayFormat: 'repeat' });
        window.open("/api/invoices/?" + queryparams);
    },


    exportInvoiceItemsAsCSV({ commit, dispatch, state }, params) {
        let filters = {};
        if (params.filtered) {
            filters.billed = state.invoices_filters.billed;
            filters.invoice_status = state.invoices_filters.status;
            filters.invoice_type = state.invoices_filters.invoice_type;
            filters.billingaccount = state.invoices_filters.billingaccount;
        }
        filters.myinvoices = 'false';
        filters.format = 'csv';
        filters.limit = 10000;
        filters.ordering = 'billing_date';
        const queryparams = qs.stringify(filters, { arrayFormat: 'repeat' });
        window.open("/api/invoiceitems/?" + queryparams);
    },


    async fetchInvoices({ commit, dispatch, state }, params) {
        commit('updateInvoicesLoading', true);
        commit('updateInvoicesLoadingErrors', null);

        if (state.invoices_cancel_source) {
            state.invoices_cancel_source.cancel("canceled");
        }

        const cancelSource = axios.CancelToken.source();
        commit('updateInvoicesCancelSource', cancelSource);

        try {
            const response = await axios.get('/api/invoices/', {
                params: state.invoices_filters,
                cancelToken: state.invoices_cancel_source?.token
            });
            commit('updateInvoices', response.data.results);
            commit('updateInvoicesCount', response.data.count);
            commit('updateInvoicesCancelSource', null);
            dispatch('fetchInvoicesStats');
            return response.data;
        } catch (xhr_error) {
            if (axios.isCancel(xhr_error)) {
                return;
            }

            const error = utils.handleError(xhr_error);
            commit('updateInvoicesLoadingErrors', error.details);
            throw utils.handleError(xhr_error);
        } finally {
            commit('updateInvoicesLoading', false);
        }
    },

    async fetchInvoicesStats({ commit, dispatch, state }, params) {
        commit('updateInvoicesStatsLoading', true);
        commit('updateInvoicesStatsLoadingErrors', null);

        if (state.invoices_stats_cancel_source) {
            state.invoices_stats_cancel_source.cancel("canceled");
        }

        const cancelSource = axios.CancelToken.source();
        commit('updateInvoicesStatsCancelSource', cancelSource);

        try {
            const response = await axios.get('/api/invoices/stats/', {
                params: state.invoices_filters,
                cancelToken: state.invoices_stats_cancel_source?.token
            });
            commit('updateInvoicesStats', response.data);
            commit('updateInvoicesStatsCancelSource', null);
            return response.data;
        } catch (xhr_error) {
            if (axios.isCancel(xhr_error)) {
                return;
            }

            const error = utils.handleError(xhr_error);
            commit('updateInvoicesStatsLoadingErrors', error.details);
            throw utils.handleError(xhr_error);
        } finally {
            commit('updateInvoicesStatsLoading', false);
        }
    },

    async fetchBillingAccounts({ commit, dispatch, state }, params) {
        commit('updateBillingAccountsLoading', true);
        commit('updateBillingAccountsLoadingErrors', null);

        try {
            const response = await axios.get('/api/billingaccounts/');
            commit('updateBillingAccounts', response.data.results);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateBillingAccountsLoadingErrors', error.details);
            throw error;
        } finally {
            commit('updateBillingAccountsLoading', false);
        }
    },



};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
