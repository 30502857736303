<template>
    <div class="py-3">
        <stroke :strike="product.archived">
            <router-link
                v-if="upc"
                :to="{
                    name: 'product',
                    params: { product_id: product.id },
                }"
            >
                {{ product.upc }}
            </router-link>
            <b
                ><template v-if="product.name">{{ product.name }}</template
                ><i class="warning--text" v-else v-translate>No name</i>
            </b>
            <template v-if="product.manufacturer && manufacturer">
                <i class="grey--text text--darken-2">
                    - {{ product.manufacturer }}</i
                ></template
            >
            <template v-if="product.description && description">
                <div class="mt-1">
                    {{ product.description }}
                </div>
            </template>
        </stroke>
    </div>
</template>

<script>
import GlobalMixins from "@/mixins/global";

export default {
    mixins: [GlobalMixins],
    props: {
        product: {
            required: true,
            type: Object,
        },
        upc: {
            type: Boolean,
            default: false,
        },
        manufacturer: {
            type: Boolean,
            default: true,
        },
        description: {
            type: Boolean,
            default: true,
        },
    },
};
</script>
