import qs from 'qs';
import store from '@/stores/store';
import axios from '@/plugins/axios';
import utils from '@/stores/utils';
import invitations from "./submodules/invitations";
import customers from "./submodules/customers";
import providers from "./submodules/providers";

const state = {
    invitation: null,
    invitation_loading: false,
    invitation_saving: false,

    invitation_accepting: false,
    invitation_declining: false,

    existing_provider: null,
    existing_customer: null,
};

const mutations = {

    updateInvitation(state, invitation) {

        if (invitation && state.invitation && invitation.id == state.invitation.id) {
            state.invitation = invitation;
            return;
        }
        state.invitation = invitation;

        if (!invitation) {
            return;
        }
    },

    updateInvitationSaving(state, saving) {
        state.invitation_saving = saving;
    },
    updateInvitationLoading(state, loading) {
        state.invitation_loading = loading;
    },
    updateInvitationLoadingErrors(state, errors) {
        state.invitation_loading_errors = errors;
    },
    updateInvitationAccepting(state, accepting) {
        state.invitation_accepting = accepting;
    },
    updateInvitationDeclining(state, declining) {
        state.invitation_declining = declining;
    },
    updateExistingProvider(state, provider) {
        state.existing_provider = provider;
    },
    updateExistingCustomer(state, customer) {
        state.existing_customer = customer;
    },
};

const actions = {

    async fetchInvitation({ commit, dispatch, state }, params) {

        let url = `/api/invitations/${state.invitation.id}/`;

        commit('updateInvitationLoading', true);

        try {
            const response = await axios.get(url, { params: { profile: "invitation" } });
            commit('updateInvitationLoading', false);
            commit('updateInvitation', response.data);
            dispatch('fetchBillingAccount');
            return response.data;
        } catch (xhr_error) {
            commit('updateInvitationLoading', false);
            throw utils.handleError(xhr_error);
        }
    },

    async deleteInvitation({ commit, dispatch, state }, params) {
        const url = `/api/invitations/${params.instance.id}/`;

        try {
            const response = await axios.delete(url);
            dispatch('fetchInvitation');
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            throw error;
        }
    },

    async acceptInvitation({ commit, dispatch, state }, params) {
        const url = `/api/invitations/${state.invitation.uuid}/accept/`;
        commit('updateInvitationAccepting', true);
        try {
            let data = {};
            if (state.invitation.provider && state.existing_customer) {
                data = { existing_customer: state.existing_customer };
            } else if (state.invitation.customer && state.invitation.initiator_data.profile != 'operator' && state.existing_provider) {
                data = { existing_provider: state.existing_provider };
            }

            const response = await axios.post(url, data);
            return response.data;
        } catch (xhr_error) {
            throw utils.handleError(xhr_error);
        } finally {
            commit('updateInvitationAccepting', false);
        }
    },



    async declineInvitation({ commit, dispatch, state }, params) {
        const url = `/api/invitations/${state.invitation.uuid}/decline/`;
        commit('updateInvitationDeclining', true);
        try {
            const response = await axios.post(url);
            return response;
        } catch (xhr_error) {
            throw utils.handleError(xhr_error);
        } finally {
            commit('updateInvitationDeclining', false);
        }
    },


    init({ commit, dispatch, state }, params) {
        commit('updateInvitation', params.invitation);
        dispatch("invitations/init", { filters: { invitation: params.invitation.id } });

        if (!state.invitation.entity) {
            dispatch("invitations/fetchInvitations");
        }
        dispatch('customers/init', { filters: { limit: 1000, connected: false } });
        dispatch('providers/init', { filters: { limit: 1000, connected: false } });
        if (params.invitation.provider) {
            dispatch('customers/fetchCustomers');
        } else {
            dispatch('providers/fetchProviders');
        }

    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    modules: {
        invitations: invitations(),
        customers: customers(),
        providers: providers(),
    }
};
