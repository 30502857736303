var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "py-3"
  }, [_c('stroke', {
    attrs: {
      "strike": _vm.product.archived
    }
  }, [_vm.upc ? _c('router-link', {
    attrs: {
      "to": {
        name: 'product',
        params: {
          product_id: _vm.product.id
        }
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.product.upc) + " ")]) : _vm._e(), _c('b', [_vm.product.name ? [_vm._v(_vm._s(_vm.product.name))] : _c('i', {
    directives: [{
      name: "translate",
      rawName: "v-translate"
    }],
    staticClass: "warning--text"
  }, [_vm._v("No name")])], 2), _vm.product.manufacturer && _vm.manufacturer ? [_c('i', {
    staticClass: "grey--text text--darken-2"
  }, [_vm._v(" - " + _vm._s(_vm.product.manufacturer))])] : _vm._e(), _vm.product.description && _vm.description ? [_c('div', {
    staticClass: "mt-1"
  }, [_vm._v(" " + _vm._s(_vm.product.description) + " ")])] : _vm._e()], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }