import qs from 'qs';
import store from '@/stores/store';
import axios from '@/plugins/axios';
import utils from '@/stores/utils';
import documents from './submodules/documents';
import customerstats from './submodules/customerstats';
import billingaccounts from './submodules/billingaccounts';
import shipments from './submodules/shipments';
import receipts from './submodules/receipts';
import orders from './submodules/orders';
import deliverysites from './submodules/deliverysites';
import invitations from "./submodules/invitations";
import stocks from "./submodules/stocks";

const state = {
    customer: null,
    customer_loading: false,
    customer_saving: false,

    monthly_stats: {},
    monthly_stats_loaded: false,
    monthly_stats_loading: false,
    monthly_stats_loading_errors: null,

    stats: null,
    stats_loading: false,
    stats_loading_errors: null,

    ask_user_creation: false,

    invoices: [],
    invoices_filters: { offset: 0, limit: 20, },
    invoices_count: 0,
    invoices_loading: false,
    invoices_loading_errors: null,

    contractlines: [],
    contractlines_filters: { offset: 0, limit: 20 },
    contractlines_count: 0,
    contractlines_loading: false,
    contractlines_loading_errors: null,

    billingaccount: null,

    catalogs: null,
    catalogs_fetching: false,
    catalogs_fetching_errors: null,

    section: null,

    creating_account: false,

};

const mutations = {
    updateInvoices(state, data) {
        state.invoices = data.results;
        state.invoices_count = data.count;
    },
    updateSection(state, section) {
        state.section = section;
    },

    updateInvoicesLoading(state, loading) {
        state.invoices_loading = loading;
    },
    updateInvoicesLoadingErrors(state, errors) {
        state.invoices_loading_errors = errors;
    },
    updateInvoicesFilters(state, filters) {
        state.invoices_filters = filters;
    },

    updateCustomer(state, customer) {

        if (customer && state.customer && customer.id == state.customer.id) {
            state.customer = customer;
            return;
        }
        state.customer = customer;
        state.stats = null;
        state.monthly_stats_loaded = false;
        state.monthly_stats = [];

        state.invoices = [];
        state.invoices_count = 0;
        state.invoices_loaded = 0;

        if (!customer) {
            state.invoices_filters = { offset: 0, limit: state.invoices_filters.limit };
            state.contractlines_filters = { offset: 0, limit: state.contractlines_filters.limit };
            return;
        }

        state.invoices_filters = {
            billed: customer.id,
            offset: 0, limit: state.invoices_filters.limit,
            ordering: '-invoice_date',
        };
        state.contractlines_filters = {
            offset: 0,
            limit: state.contractlines_filters.limit,
            billed: customer.id,
        };
    },

    updateCustomerSaving(state, saving) {
        state.customer_saving = saving;
    },
    updateCustomerLoading(state, loading) {
        state.customer_loading = loading;
    },

    updateStats(state, stats) {
        state.stats = stats;
        state.stats_loading = false;
        state.stats_loading_errors = null;
    },
    updateStatsLoading(state, loading) {
        state.stats_loading = loading;
        state.stats_loading_errors = null;
    },
    updateStatsLoadingErrors(state, stats_loading_errors) {
        state.stats = {};
        state.stats_loading = false;
        state.stats_loading_errors = stats_loading_errors;
    },

    updateMonthlyStats(state, stats) {
        state.monthly_stats = stats;
        state.monthly_stats_loaded = true;
    },
    updateMonthlyStatsLoading(state, loading) {
        state.monthly_stats_loading = loading;
    },
    updateMonthlyStatsLoadingErrors(state, errors) {
        state.monthly_stats_loading_errors = errors;
    },

    updateContractLines(state, data) {
        state.contractlines = data.results;
        state.contractlines_count = data.count;
        state.contractlines_loading_errors = {};
        state.contractlines_loading = false;
    },
    updateContractLinesLoading(state, loading) {
        state.contractlines_loading = loading;
        state.contractlines_loading_errors = {};
    },
    updateContractLinesLoadingErrors(state, errors) {
        state.contractlines_loading_errors = errors;
        state.contractlines_loading = false;
    },
    updateContractLinesFilters(state, filters) {
        state.contractlines_filters = filters;
    },

    updateBillingAccount(state, billingaccount) {
        state.billingaccount = billingaccount;
        state.billingaccount_loading = false;
        state.billingaccount_loading_errors = null;
    },
    updateBillingAccountLoading(state, billingaccount_loading) {
        state.billingaccount_loading = billingaccount_loading;
    },
    updateBillingAccountLoadingErrors(state, billingaccount_loading_errors) {
        state.billingaccount = null;
        state.billingaccount_loading = false;
        state.billingaccount_loading_errors = billingaccount_loading_errors;
    },

    updateFetchingCatalogs(state) {
        state.catalogs = [];
        state.catalogs_fetching = true;
        state.catalogs_fetching_errors = null;
    },

    updateCatalogs(state, catalogs) {
        state.catalogs = catalogs;
        state.catalogs_fetching = false;
    },

    updateFetchingCatalogsErrors(state, errors) {
        state.catalogs_fetching_errors = errors;
    },

    updateCreatingAccount(state, creating_account) {
        state.creating_account = creating_account;
    },

};

const actions = {

    exportInvoicesAsCSV({ commit, dispatch, state }, params) {
        let filters = {};
        if (params.filtered) {
            filters = Object.assign({}, state.invoices_filters);
            filters.offset = null;
        }
        filters.format = 'csv';
        filters.limit = 10000;
        const queryparams = qs.stringify(filters, { arrayFormat: 'repeat' });
        window.open("/api/invoices/?" + queryparams);
    },

    async fetchCatalogs({ commit, dispatch, state }, params) {
        if (store.getters['session/current_user_permissions'].indexOf("catalog.view_catalogassignment") == -1) {
            return;
        }
        if (store.getters['session/current_user_permissions'].indexOf("catalog.view_catalog") == -1) {
            return;
        }

        try {
            commit('updateFetchingCatalogs');
            const response = await axios.get('/api/catalogassignments/', { params: { customer: state.customer.id } });
            let catalogs = response.data.results.map((item) => item.catalog_data);
            commit('updateCatalogs', catalogs);
            return catalogs;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateFetchingCatalogsErrors', error.details);
            throw error;
        }
    },

    fetchInvoices({ commit, dispatch, state }, params) {
        commit('updateInvoicesLoading', true);
        commit('updateInvoicesLoadingErrors', null);

        return new Promise((resolve, reject) => {
            axios.get('/api/invoices/', { params: state.invoices_filters })
                .then((response) => {
                    commit('updateInvoices', response.data);
                    resolve(response.data);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateInvoicesLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateInvoicesLoading', false);
                });
        });
    },

    fetchCustomerStats({ commit, dispatch, state }, params) {
        commit('updateStatsLoading', true);
        if (!state.customer) {
            return;
        }
        return new Promise((resolve, reject) => {
            let url = `/api/customers/${state.customer.id}/stats/`;
            axios.get(url, { params: { profile: "customer" } })
                .then((response) => {
                    commit('updateStats', response.data);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateStatsLoadingErrors', error.details);
                    reject(error);
                })
        });
    },

    fetchCustomer({ commit, dispatch, state }, params) {

        commit('updateCustomerLoading', true);

        let url = null;
        if (params && params.customer_id) {
            url = `/api/customers/${params.customer_id}/`;
        } else if (state.customer) {
            url = `/api/customers/${state.customer.id}/`;
        } else {
            throw 'No customer to fetch';
        }

        return new Promise((resolve, reject) => {
            axios.get(url, { params: { profile: "customer" } })
                .then((response) => {
                    commit('updateCustomerLoading', false);
                    commit('updateCustomer', response.data);
                    dispatch('fetchBillingAccount');
                    resolve(response.data);
                })
                .catch((xhr_error) => {
                    commit('updateCustomerLoading', false);
                    reject(utils.handleError(xhr_error));
                });
        });
    },

    deleteCustomer({ commit, dispatch, state }, params) {
        const url = `/api/customer/${params.instance.id}/`;

        return new Promise((resolve, reject) => {
            axios.delete(url)
                .then((response) => {
                    dispatch('fetchCustomer');
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    reject(error);
                });
        });
    },

    fetchContractLines({ commit, dispatch, state }, params) {
        commit('updateContractLines', []);
        commit('updateContractLinesLoading', true);

        return new Promise((resolve, reject) => {

            axios.get("/api/contractlines/", { params: state.contractlines_filters })
                .then((response) => {
                    commit('updateContractLines', response.data);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateContractLinesLoadingErrors', error.details);
                    reject(error);
                });
        });
    },

    deleteContractLine({ commit, dispatch, state }, params) {
        return new Promise((resolve, reject) => {
            const url = `/api/contractlines/${params.instance.id}/`;

            axios.delete(url)
                .then((response) => {
                    resolve(response);
                })
                .catch((xhr_error) => {
                    let errors = utils.handleError(xhr_error);
                    reject(errors);
                });
        });
    },


    fetchBillingAccount({ commit, dispatch, state }, params) {
        if (store.getters['session/current_user_permissions'].indexOf("billing.view_billingaccount") == -1) {
            return;
        }
        dispatch('activebillingaccounts/fetchBillingAccounts').then((response) => {
            if (response.data.results.length > 0) {
                commit('updateBillingAccount', response.data.results[0]);
            }
        });
    },

    init({ commit, dispatch, state }, params) {
        commit('updateBillingAccount', null);
        commit('updateStats', null);
        commit('updateCatalogs', null);

        commit('updateCustomer', params.customer);
        commit('customerstats/updateCustomer', params.customer);

        dispatch('documents/init', {
            filterTag: `customer:${params.customer.id}`,
            createTags: [
                `customer:${params.customer.id}`,
            ]
        });
        dispatch('deliverysites/init', { url: `/api/customers/${params.customer.id}/deliverysites/` });

        if (store.getters['session/current_user_mode'] == "operator") {
            dispatch('shipments/init', { initiator: params.customer.id });
            dispatch('receipts/init', { owner: params.customer.entity });
            dispatch('stocks/init', { filters: { customer: params.customer.id } });
            dispatch('shipments/fetchShipments');
            dispatch('receipts/fetchReceipts');
            dispatch('stocks/fetchStocks');
        } else {
            dispatch('orders/init', { url: '/api/customerorders/', filters: { buyer: params.customer.id, suborder: false } });
            dispatch('orders/fetchOrders');
            dispatch('shipments/init', { recipient: params.customer.id });
            dispatch('shipments/fetchShipments');
        }

        dispatch('activebillingaccounts/init', {
            filters: { customer: params.customer.id }
        });

        dispatch('fetchBillingAccount');
        dispatch('fetchCustomerStats');
        dispatch('fetchCatalogs');
        dispatch('customerstats/fetchStats');
        dispatch('documents/fetchDocuments');
        dispatch('deliverysites/fetchDeliverySites');

        dispatch("invitations/init", { filters: { customer: params.customer.id } });
        if (!state.customer.entity) {
            dispatch("invitations/fetchInvitations");
        }
    },


    async inviteCustomer({ commit, dispatch, state }, params) {

        try {
            await dispatch("invitations/saveInvitation", {
                data: {
                    customer: state.customer.id,
                    email_or_safetee_id: params.email_or_safetee_id,
                }
            });
        } catch (error) {
            if (error.code == 400) {
                throw {
                    code: 400,
                    details: { non_field_errors: [error.details.email_or_safetee_id] },
                };
            }
            throw error;
        }
        dispatch("invitations/fetchInvitations");
    },

    async cancelCustomerInvitation({ commit, dispatch, state }, params) {

        try {
            await dispatch("invitations/deleteInvitation", {
                instance: state.invitations.invitations[0],
            });
        } catch (error) {
            if (error.code == 400) {
                throw error.details.email_or_safetee_id;
            }
            throw error;
        }
        dispatch("invitations/fetchInvitations");
    },

    async createAccount({ commit, dispatch, state }, params) {

        commit('updateCreatingAccount', true);
        try {
            let response = await axios.post(`/api/customers/${state.customer.id}/createaccount/`, params);
            commit('updateCreatingAccount', false);
            dispatch('fetchCustomer');
            return response.data;
        } catch (xhr_error) {
            commit('updateCreatingAccount', false);
            throw utils.handleError(xhr_error);
        }
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    modules: {
        documents: documents(),
        customerstats: customerstats(),
        activebillingaccounts: billingaccounts(),
        shipments: shipments(),
        receipts: receipts(),
        orders: orders(),
        deliverysites: deliverysites(),
        invitations: invitations(),
        stocks: stocks(),
    }
};
